<template>
  <div class="myCalendar">
    <div class="head">
      <div class="showPopup" @click="handshowDtPicker">
        <svg-icon icon-class="interview_rili"></svg-icon>
        {{ yymmdd }}
        <span>
          <svg-icon icon-class="dropdown"></svg-icon>
        </span>
      </div>
    </div>
    <vue-hash-calendar
      :visible="show"
      :pickerType="'date'"
      format="YY/MM/DD"
      :defaultDatetime="selectDate"
      :showTodayButton="false"
      :isShowAction="false"
      :markDate="markDate"
      :min-date="nminDate"
      :max-date="nmaxDate"
      @change="getdate"
      :disabledWeekView="true"
    >
      <div slot="day" slot-scope="scope">
        <div class="day">{{ scope.date.day }}</div>
        <div class="lunar">{{ lunar(scope) }}</div>
      </div>
    </vue-hash-calendar>
    <van-popup v-model="showDtPicker" round position="bottom">
      <van-datetime-picker
        v-model="selectDate2"
        type="date"
        :min-date="nminDate"
        :max-date="nmaxDate"
        :title="'选择年月日'"
        @confirm="onDtConfirm"
        @cancel="showDtPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import { getLunar } from "chinese-lunar-calendar";
export default {
    name: "myCalendar",
    props: {
        // 最小可选日期
        minDate: {
            type: Date,
            default: null,
        },
        // 最大可选日期
        maxDate: {
            type: Date,
            default: null,
        },
        // 显示
        show: {
            type: Boolean,
            default: true,
        },
        yymmddArr:{
            type:Array,
            default:[]
        }
    },
    data() {
        let sData = null;
        if(window.sessionStorage.getItem("recordSigninNowData")){
            sData = new Date(window.sessionStorage.getItem("recordSigninNowData"))
        }else{
            sData = new Date()
        }
        return {
            selectDate: sData, // 选中时间
            selectDate2: sData, // 选中时间
            markDate: [],
            showDtPicker: false,
            nminDate: this.minDate || this.dd("min"),
            nmaxDate: this.maxDate || this.dd("max"),
            yymmdd: parseTime(this.selectDate, "{y}/{m}/{d}"),
        };
    },
    watch: {
        yymmddArr:{
            handler(n) {
                if (n) {
                    this.markDate = [];
                    if(n.length > 0){
                        n.forEach(element => {
                            let obj = {
                                color:"red",
                                type:"dot",
                                date:element.yymmdd.split()
                            }
                            this.markDate.push(obj);
                        });
                    }
                    console.log(this.markDate,'yymmddArr');
                }
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        // 日期变更（最终）
        getdate(d) {
            this.yymmdd = d;
            this.$emit("outDate", d);
        },
        // 显示下拉框
        handshowDtPicker() {
            this.selectDate2 = this.selectDate;
            this.showDtPicker = true;
        },
        // 下拉框选择日期
        onDtConfirm(v) {
            this.selectDate = v;
            this.getdate(parseTime(v, "{y}/{m}/{d}"));
            this.showDtPicker = false;
        },
        //日期控件的最大日期/最小日期
        dd(m) {
            const d = new Date();
            const year = d.getFullYear();
            const month = d.getMonth();
            const day = d.getDate();
            if (m === "min") {
                return new Date(year - 100, month, day);
            } else {
                return new Date(year + 30, month, day);
            }
        },
        // 农历+节假日
        lunar(scope) {
            const getLunarDay = getLunar(
                scope.date.year,
                scope.date.month + 1,
                scope.date.day
            );
            const v =
                scope.date.month == 0 && scope.date.day == 1
                ? "元旦"
                : scope.date.month == 4 && scope.date.day == 1
                ? "劳动节"
                : scope.date.month == 9 && scope.date.day == 1
                ? "国庆"
                : scope.date.month == 2 && scope.date.day == 8
                ? "妇女节"
                : getLunarDay.dateStr == "正月初一"
                ? "春节"
                : getLunarDay.dateStr == "正月十五"
                ? "元宵"
                : getLunarDay.dateStr == "五月初五"
                ? "端午"
                : getLunarDay.dateStr == "八月十五"
                ? "中秋"
                : getLunarDay.solarTerm
                ? getLunarDay.solarTerm
                : getLunarDay.dateStr.indexOf("初一") > -1
                ? getLunarDay.dateStr.substring(0, 2)
                : getLunarDay.dateStr.substring(2);
            return v;
        },
    },
};
</script>

<style lang="less" scoped>
.myCalendar {
    .head {
        font-family: Source Han Sans CN;
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 100%;
        background: #fff;
        height: 2em;
        .showPopup {
            color: #333;
            span {
                color: #999;
                font-size: 24px;
                margin-left: 10px;
                .svg-icon {
                margin: 0;
                }
            }
            .svg-icon {
                margin: 0 30px;
            }
        }
    }
    /deep/.calendar_inline{
        min-height: 2.02rem !important;
    }
    /deep/.hash-calendar {
        .calendar_content {
            min-height: 150px !important;
            .calendar_body {
                .calendar_week {
                    font-size: 28px;
                    font-family: Source Han Sans CN;
                    color: #333;
                }
            }
        }
    }
    /deep/ .calendar_day {
        margin-bottom: 0.5vw !important;
        div {
            text-align: center;
        }
        .lunar {
            font-size: 20px;
        }
    }
}
</style>